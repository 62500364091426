import Icon, { UserOutlined } from '@ant-design/icons';
import { NavBar } from 'antd-mobile';
import { useHistory, useModel } from 'umi';
import styles from './index.less';

const UserSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      version="1.1"
      width="32"
      height="32"
      viewBox="0 0 51 36.000064849853516"
    >
      <g>
        <path
          d="M8.44464,34.9379Q9.33864,36,16.581200000000003,36L37.4782,36Q43.5069,36,44.3748,35.0472Q45.4774,33.8366,45.0818,32.2415Q44.7767,31.0112,39.589,28.1233L39.5489,28.101Q30.5938,23.1158,29.3924,22.2036Q28.7452,21.7122,29.0224,20.688Q29.2683,19.7798,29.3721,19.705Q33.513,16.7225,33.513,10.5Q33.513,6.23585,31.2635,3.18857Q28.9096,0.0000010000000000287557,25.5,0.0000010000000000287557Q22.0904,0,19.7365,3.18857Q17.487,6.23585,17.487,10.5Q17.487,17.0913,21.9913,19.951L21.9304,20.0244Q22.1566,20.4339,22.2651,20.8283Q22.5217,21.7611,21.9333,22.1955Q18.4499,24.7672,14.4051,27.1556Q13.6658,27.5922,12.3802,28.3377Q8.03679,30.8564,7.76293,31.9481Q7.34169,33.6274,8.44464,34.9379ZM25.5,34L16.581200000000003,34Q10.26941,34,9.97478,33.65Q9.53043,33.122,9.70283,32.434799999999996Q9.77109,32.1626,13.3835,30.0678Q14.6762,29.3182,15.422,28.8778Q19.5552,26.4372,23.1212,23.8045Q24.8143,22.5545,24.1935,20.2978Q23.7542,18.7013,23.0633,18.2626Q19.487,15.992,19.487,10.5Q19.487,6.89409,21.3456,4.3764Q23.0999,2,25.5,2Q27.9001,2,29.6544,4.3764Q31.513,6.8941,31.513,10.5Q31.513,15.6982,28.2033,18.0821Q27.5233,18.5719,27.0919,20.1654Q26.4626,22.4902,28.183,23.7964Q29.4972,24.7943,38.5761,29.8484L38.6162,29.8708Q43.0447,32.336,43.1406,32.722899999999996Q43.2789,33.2803,42.8962,33.7005Q42.6233,34,37.4782,34L25.5,34Z"
          fillRule="evenodd"
          fill="#181818"
          fillOpacity="1"
        />
      </g>
    </svg>
  );
};
const UserIcon = (props) => {
  return <Icon component={UserSvg} {...props} />;
};
const nameObj = {
  1: '程序上传',
  2: '程序下发',
  3: '在线调试',
  4: '特批调试',
  5: '设备注销',
  6: '调试软件上传',
};
function findRouteName(routes, path, query) {
  if (path === '/login/') return;
  try {
    const { type, isApprove } = query;
    if (type && path.startsWith('/scp/message/detail')) {
      return `${nameObj[type]}${isApprove ? '审批' : '申请'}`;
    }
    return routes.find((i) => i.path.startsWith(path)).name || '页面未找到';
  } catch (error) {}
}
const Header = (props) => {
  const {visible, setVisible} = useModel('useDetailOpen',model => ({ visible: model.visible, setVisible: model.setVisible }));

  const histry = useHistory();
  const path = location.pathname;
  const name = findRouteName(props.routes, path, histry.location.query);
  const back = () => {
    if(visible) {
      setVisible(false)
    } else {
      histry.push('/')
    }
  };
  const right = (
    <UserIcon
      onClick={() => {
        histry.push('/scp/my/');
      }}
    />
  );
  return (
    <div className={styles.header}>
      <NavBar
        style={{ '--height': '42px' }}
        right={path !== '/scp/my/' && right}
        back={path !== '/scp/index/' ? '' : null}
        onBack={back}
      >
        {name}
      </NavBar>
    </div>
  );
};

export default Header;
