import { useEffect, useState } from 'react'
import { history } from 'umi'
export default function useDetailOpen() {
  const [visible, setVisible] = useState(false)
  useEffect(()=>{
    history.listen(()=>{
      setVisible(false)
    })
  },[])
  return {visible, setVisible}
}
