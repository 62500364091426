export default [
  {
    path: '/login/',
    layout: false, //关闭菜单、头部等信息，只显示内容
    name: '登录',
    component: './LoginManage',
  },
  // {
  // path: '/scp/',
  // routes: [
  // {
  //   path: '/scp/',
  //   redirect: '/scp/index/',
  // },
  {
    path: '/scp/message/detail',
    component: './MessageDetail'
  },
  {
    path: '/scp/index/',
    access: 'wechat:index',
    name: '首页',
    component: './Index',
  },
  {
    path: '/scp/my/',
    name: '我的',
    component: './My',
  },
  {
    path: '/scp/info/',
    name: '个人信息',
    component: './My/components/UserInfo',
  },
  {
    path: '/scp/approvemanage/uploadapprove/',
    name: '程序上传审批',
    access: 'wechat:approvemanage:uploadapprove',
    component: './UploadApproval',
  },
  {
    path: '/scp/approvemanage/downloadapprove/',
    name: '程序下发审批',
    access: 'wechat:approvemanage:downloadapprove',
    component: './DownloadApproval',
  },
  {
    path: '/scp/approvemanage/commissionapprove/',
    name: '在线调试审批',
    access: 'wechat:approvemanage:commissionapprove',
    component: './OnLineApproval',
  },
  {
    path: '/scp/approvemanage/specialapproval/',
    name: '特批调试审批',
    access: 'wechat:approvemanage:specialapproval',
    component: './SpecialApproval',
  },
  {
    path: '/scp/approvemanage/equiplogoffapprove/',
    name: '设备注销审批',
    access: 'wechat:approvemanage:equiplogoffapprove',
    component: './EquipLogoffApproval',
  },
  {
    path: '/scp/approvemanage/downtimeapprove/',
    name: '平台宕机调试审批',
    access: 'wechat:approvemanage:downtimeapprove',
    component: './DowntimeApproval'
  },
  {
    path: '/scp/approvemanage/softwareuploadapprove/',
    name: '调试软件上传审批',
    access: 'wechat:approvemanage:softwareuploadapprove',
    component: './SoftwareUploadApproval',
  },
  {
    path: '/scp/personalapply/uploadapply/',
    name: '程序上传申请',
    access: 'wechat:personalapply:uploadapply',
    component: './UploadApply',
  },
  {
    path: '/scp/personalapply/downloadapply/',
    name: '程序下发申请',
    access: 'wechat:personalapply:downloadapply',
    component: './DownloadApply',
  },
  {
    path: '/scp/personalapply/commissionapply/',
    name: '在线调试申请',
    access: 'wechat:personalapply:commissionapply',
    component: './OnLineApply',
  },
  {
    path: '/scp/approvemanage/equiplogoffapply/',
    name: '设备注销申请',
    access: 'wechat:personalapply:equiplogoffapply',
    component: './EquipLogoffApply',
  },
  {
    path: '/scp/approvemanage/specialapply/',
    name: '特批调试申请',
    access: 'wechat:personalapply:specialapply',
    component: './SpecialApply',
  },
  {
    path: '/scp/approvemanage/downtimeapply/',
    name: '平台宕机调试申请',
    access: 'wechat:personalapply:downtimeapply',
    component: './DowntimeApply'
  },
  {
    path: '/scp/approvemanage/softwareuploadapply/',
    name: '调试软件上传申请',
    access: 'wechat:personalapply:softwareuploadapply',
    component: './SoftwareUploadApply',
  },
  {
    path: '/scp/securitycontrol/createkey/',
    name: '密钥生成',
    access: 'wechat:securitycontrol:createkey',
    component: './Createkey',
  },
  {
    path: '/scp/securitycontrol/myconnectkey/',
    name: '我的连接码',
    access: 'wechat:securitycontrol:myconnectkey',
    component: './MyConnectKey',
  },
  {
    path: '/scp/securitycontrol/genneratorcode/',
    name: '特批调试授权',
    access: 'wechat:securitycontrol:genneratorcode',
    component: './GenneratorCode',
  },
  // ],
  // },
  {
    path: '/initsystem/',
    // layout: false, //关闭菜单、头部等信息，只显示内容
    headerRender: false,
    footerRender: false,
    name: '初始化',
    component: './Createkey',
  },
  // {
  //   path: '/',
  //   redirect: '/scp/',
  // },
  {
    component: './404'
  },
];
