/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { notification, message } from 'antd';
import cookie from 'react-cookies';
import defaultSettings from '../../config/defaultSettings';
import { history } from 'umi';
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
/**
 * 异常处理程序
 */

const errorHandler = (error) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    if (status == 401) {
      message.destroy();
      // message.info('登录信息失效');
    } else {
      message.destroy();
      message.info(`请求错误 ${status}: ${url}`);
    }
  } else if (!response) {
    message.destroy();
    message.info(`您的网络发生异常，无法连接服务器`);
  }
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// 请求拦截
request.interceptors.request.use((url, options) => {
  return {
    url: url,
    options: {
      ...options,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        expires: -1,
        satoken: cookie.load('code'),
      },
    },
  };
});

// TOKEN_INVALID(400303, 'token无效'),
// TOKEN_TIMEOUT(400304, 'token已过期'),
// TOKEN_OTHER(400307, 'token异常'),
// NO_SESSION(400211, '登录信息失效'),
// CREDENTIALS_EXPIRED(400212, '登录凭证已过期，请修改密码'),
// CREDENTIALS_EXCEPTION(400213, '登录凭证异常，请重新登录'),

// 返回拦截
request.interceptors.response.use(async (response, options) => {
  if (response.url.indexOf('export') != -1 || response.url.indexOf('Export') != -1) {
    return response;
  }
  const data = await response.clone().json();
  if (data.code !== 0) {
    message.info(data.msg);
  }
  // 判断是否未登录
  if (
    data &&
    (response.status == 401 ||
      data.code == 400307 ||
      data.code == 400303 ||
      data.code == 400304 ||
      data.code == 400211 ||
      data.code == 400213 ||
      data.msg == 'token异常' ||
      data.msg == 'token已过期' ||
      data.msg == '登录信息失效' ||
      data.msg == '登录凭证异常，请重新登录' ||
      data.msg == 'token无效')
  ) {
    cookie.remove('code', { path: '/' });
    localStorage.removeItem('antd-pro-authority');
    // 重定向至登录页
    if (window.location.pathname.startsWith('/scp/message/detail')) {
      history.push({
        pathname: `/login/`,
        query: {
          redirect: location.pathname + location.search,
        },
      });
    } else {
      history.push({
        pathname: `/login/`,
      });
    }
  }
  return response;
});

export default request;
