import request from '@/utils/request';

/*
 公共接口
 */

// 获取用户信息
export async function getUserInfo(params) {
  return request('/api-uaa/users/current', {
    method: 'GET',
    params,
  });
}
//获取用户权限
export async function getPermission(params) {
  return request('/api-uaa/permissions/current', {
    method: 'GET',
    params
  });
}
export async function getPermissionTree(params) {
  return request('/api-uaa/permissions/current/tree',{
    method: 'GET'
  })
}
//系统初始化 
export async function getSystemStatus(params) {
  return request('/api-wechat/system/status', {
    method: 'GET',
    params,
  });
};

//审批状态
export async function getReviewStates(params) {
  return request('/api-wechat/dictionaries/reviewStatuses', {
    method: 'GET',
    params,
  });
}

// 站点类型
export async function getSiteTypes(params) {
  return request('/api-wechat/dictionaries/siteTypes', {
    method: 'GET',
    params,
  });
}
//应用场景类型
export async function getPackageScenarios(params) {
  return request('/api-wechat/dictionaries/softwarePackageScenarios', {
    method: 'GET',
    params,
  });
}

//操作类型
export async function getOpLogName(params) {
  return request('/api-wechat/dictionaries/operationLogNames', {
    method: 'GET',
    params,
  });
}

// 二级审批人
export async function getSecondReview(params) {
  return request('/api-wechat/users/secondReview', {
    method: 'GET',
    params,
  });
}